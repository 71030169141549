.product-main-image {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: auto;
    padding: 0px;
}
.product-main-image.dynamic-height {
    max-height: unset;
    max-width: 100%;
    height: 100%;
    width: auto;
}
.product-main-image.dynamic-width {
    max-height: calc(100vh - 158px);
    max-width: unset;
    height: auto;
    width: 100%;
}
.pre-loader-img.pre-inrollment.pre-loader {
    height: 100vh;
}
.pre-loader-img {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pre-loader-img.top-content {
    position: relative;
    top: -50px;
}
.pre-loader-img img {
    width: 75px;
}
.pre-loader-img.bottompage-loader {
    position: fixed;
    top: 0;
    right: 0;
    background: #0000007d;
    left: 0;
    z-index: 9999;
}
.pre-loader-img img.loader-boicon {
    width: 35px;
    position: absolute;
}
.maaintain-height {
    position: relative;
    height: 85px;
    width: 85px;
}
.pre-loader-img.top-content img.loader-boicon {
    width: 35px;
    position: absolute;
    top: 20px;
    right: 25px;
}
.pre-loader .loader-boicon {
    top: 20px;
    right: 25px;
}
.pre-loader-img.bottompage-loader {
    position: fixed;
    top: 0;
    right: 0;
    background: #0000007d;
    left: 0;
    z-index: 9999;
}