
.px-btn {
	padding: 0 50px 0 20px;
	line-height: 50px;
	position: relative;
	display: inline-block;
	color: $px-white;
	background: none;
	border: none;
	&:focus {
		outline: none;
		box-shadow: none !important;
	}
	span {
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
		position: relative;
		z-index: 1;
	}
	.arrow {
	    width: 13px;
	    height: 2px;
	    background: currentColor;
	    display: inline-block;
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    margin: auto;
	    right: 25px;
	    &:after {
    	    width: 8px;
		    height: 8px;
		    border-right: 2px solid currentColor;
		    border-top: 2px solid currentColor;
		    content: "";
		    position: absolute;
		    top: -3px;
		    right: 0;
		    display: inline-block;
		    @include rotate(45);
	    }
	}
	&:before {
		content: "";
	    position: absolute;
	    top: 0;
	    left: 0;
	    display: block;
	    background: transparent;
	    border: 1px solid $px-theme;
	    border-right: 1px solid transparent;
	    @include transition(ease all 0.35s); 
	    width: 50px;
	    height: 50px;
	}
	&:hover {
		color: $px-white;
		&:before {
			background: $px-theme;
			width: 100%;
			border-color: $px-theme;
		}
	}
	&.white {
		color: $px-white;
		&:before {
			border: 1px solid rgba($px-white, 0.6);
			border-right: 1px solid transparent;
		}
		&:hover {
			color: $px-white;
			&:before {
				background: $px-theme;
				width: 100%;
				border-color: $px-theme;
			}
		}
	}
}

.px-btn-arrow {
	padding: 0 50px 0 0;
	line-height: 20px;
	position: relative;
	display: inline-block;
	color: $px-theme;
	@include transition(ease all 0.3s);
	span {
		font-size: 15px;
		font-weight: 500;
		position: relative;
		z-index: 1;
	}
	.arrow {
	    width: 13px;
	    height: 2px;
	    background: currentColor;
	    display: inline-block;
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    margin: auto;
	    right: 25px;
	    @include transition(ease all 0.3s);
	    &:after {
    	    width: 8px;
		    height: 8px;
		    border-right: 2px solid currentColor;
		    border-top: 2px solid currentColor;
		    content: "";
		    position: absolute;
		    top: -3px;
		    right: 0;
		    display: inline-block;
		    @include rotate(45);
	    }
	}
	&:hover {
		color: $px-white;
		.arrow {
			right: 20px;
		}
	}
}

/* Video 
----------------*/
.video-btn {
    position: relative;
    width: 80px;
    height: 80px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    @include transition(ease all 0.55s);
    z-index: 1;
    border: none;
    &:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		border-radius: 50%;
		animation: pulse-border 1500ms ease-out infinite;
		pointer-events: none;
		z-index: -1;
	}
	&.white {
		background: $px-white;
		&:after {
			background: $px-white;
		}
		span {
			color:$px-theme;
		}
	}
	&.theme {
		background: $px-theme;
		&:after {
			background: $px-theme;
		}
		span {
			color:$px-white;
		}
	}


	span {
	    border-top: 15px solid transparent;
	    border-bottom: 15px solid transparent;
	    border-left: 20px solid currentcolor;
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 9px;
	    right: 0;
	    margin: auto;
	    width: 0px;
	    height: 0px;
	}
}

@keyframes pulse-border {
  0% {
    @include scale(0.8);
    opacity: 1;
  }
  100% {
    @include scale(2);
    opacity: 0;
  }
}