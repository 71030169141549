/* ------------------------
*	General
------------------------*/
body {
	font-family: $px-font-family;
	font-size: $px-base-font;
	color: $px-body-color;
	font-weight: 400;
	line-height: 1.8;
	font-smoothing: antialiased;
}

.dark-theme {
	color: #888888;
}

html {
	overflow-x: hidden;
}

a {
	color: $px-theme;
	@include px-hover {
		color: darken($px-theme, 8%);
		text-decoration: none;
	}
}
img {
	max-width: 100%;
}


.fa, 
.fab, 
.fal, 
.far, 
.fas {
	line-height: inherit;
}

mark {
    background-image: linear-gradient(rgba($px-theme, 0.6), rgba($px-theme, 0.6));
    background-size: 100% 3px;
    background-repeat: no-repeat;
    background-position: 0 bottom;
    background-color: transparent;
    padding: 0;
    color: currentColor;
}

/* ----------------------
*	Loading
---------------------------*/
#loading {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $px-theme;
	z-index: 99999;
}
.load-circle {
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 50px;
	 span {
	  display: inline-block;
	  width: 64px;
	  height: 64px;
	  &:after {
		  content: " ";
		  display: block;
		  width: 46px;
		  height: 46px;
		  margin: 1px;
		  border-radius: 50%;
		  border: 5px solid $px-white;
		  border-color: $px-white transparent $px-white transparent;
		  animation: lds-dual-ring 1.2s linear infinite;	
	  }
	}
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



/* ----------------------
*	owl 
---------------------------*/
.owl-dots {
	text-align: center;
	.owl-dot {
		display: inline-block;
		vertical-align: top;
		width: 10px;
		height: 10px;
		background: $px-white;
		border: 1px solid $px-theme;
		@include transition(ease all 0.55s);
		margin: 0 5px;
		.owl-carousel-white & {
			border-color: $px-white;
			background: none;
		}
		&.active {
			background: $px-theme;
			.owl-carousel-white & {
				background: $px-white;
			}
		}
	}
}

.owl-carousel {
	.owl-item {
		img {
			width: auto;
			max-width: 100%;
		}
	}
}
