@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


// Color system
$px-theme:						#ea4020 !default; // Main theme
$px-body-color:					#e4e4e6 !default; // Body text color

$px-black:						#000000 !default; 
$px-white:						#ffffff !default;
$px-dark:						#131416 !default;
$px-gray:						#1c1d24 !default;
$px-gray-alt:					#373b47 !default;


// Font
$px-base-font:					15px !default;

$px-font-family:				'Poppins', sans-serif; // Main Font Family
$px-font-alt:					'Poppins', sans-serif; // Main Font Family

// Custom Bg
$px-yellow:						#f5a416 !default;
$px-green:						#11e279 !default;
$px-blue:						#49bedf !default;
$px-pink:						#f12699 !default;

$box-shadow:					rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
$box-shadow-lg:					rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;;

// Breakpoints
$px-media-xsm:					567px !default;
$px-media-sm:					767px !default;
$px-media-md:					991px !default;
$px-media-lg:					1200px !default;